









































































import { Component, Vue, Watch } from "vue-property-decorator";

import { articleTypes ,remoteServiceBaseUrl} from "@/libs/appconst";
// import {} from '@/libs/appconst';

@Component
export default class ArticleList extends Vue {
  pagerHeader: any = {
    title: "文章列表",
    desc: "文章列表",
    breadcrumb: ["文章管理", "文章列表"]
  };
  loading: boolean = false;
  filter: any = {
    page: 1,
    size: 20,
    keywords:"",
    categoryId: ""
  };
  tableData: any = {
    items: [],
    totalCount: 0
  };
  categories: any[] = [];
  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.getData();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.getData();
  }
  async getData() {
    this.loading = true;
    try {
      let res = await this.$ajax.get("/api/services/app/Article/GetPagedList", {
        params: {
          ...this.filter
        }
      });

      if (res.data.success) {
        this.tableData = res.data.result;
        this.tableData.items.forEach((element:any) => {
            element['url']=remoteServiceBaseUrl+'/Article/Detail?id='+element.id
        });
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }
  search() {
    this.filter.page = 1;
    this.getData();
  }
  activated() {
    this.getData();
  }
  created() {
    this.filter.size = this.systemSettings.pageSize;
    this.getData();
    this.readerCategory();
  }
  getTypeDesc(type: number) {
    return articleTypes[type];
  }
  async readerCategory() {
    try {
      var res = await this.$ajax.get("/api/services/app/Category/GetAll", {
        params: {
          type: 3
        }
      });
      this.categories = res.data.result;
    } catch (error) {}
  }
  async deleteArticle(id: number) {
    var result = await this.$confirm("确认要删除该文章吗?");
    if (result == "confirm") {
      let loading = this.$loading({
        text: "请稍后..."
      });
      try {
        var res = await this.$ajax.delete("/api/services/app/Article/Delete", {
          params: {
            id: id
          }
        });
        if (res.data.success) {
          this.$notify.success("删除成功");
          this.getData();
        }
      } catch (error) {}
      loading.close();
    }
  }
}
